import React from 'react'
import styled from 'styled-components'
import Button from './Button'

import img1 from '../assets/astro (1).png'
import img2 from '../assets/astro (2).png'
import img3 from '../assets/astro (3).png'
import img4 from '../assets/imgxshib (1).png'
import img5 from '../assets/imgxshib (2).png'


const Section = styled.section`
width: 100vw;
height: 25rem;
position: relative;
border-top: 2px solid ${props => props.theme.text};
border-top: 2px solid ${props => props.theme.text};

background-color: #25A18E;

display: flex;
justify-content: center;
align-items: center;


overflow: hidden;

@media (max-width: 48em) {
  height: 15rem;
  flex-direction: column;
}
`

const ImgContainer= styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

display: flex;
justify-content: center;
align-items: center;
opacity: 0.2;

img{
    width: 15rem;
    height: auto;
}

@media (max-width: 48em) {
  img{
    width: 10rem;
    height: auto;
}
}
`

const Title = styled.h1`
font-size: ${props => props.theme.fontxxxl};
color:${props => props.theme.body} ;
padding: 1rem 2rem;
z-index: 10;
width: 35%;
text-transform: capitalize;

text-shadow: 1px 1px 2px ${props => props.theme.text};

@media (max-width: 48em) {

  font-size: ${props => props.theme.fontxl};
  padding: 2rem 0;
  text-align: center;

  width: 100%;

}
`



const Banner = () => {
  return (
    <Section>
        <ImgContainer>
            <img src={img1} alt='Astro Shiba' />
            <img src={img2} alt='Astro Shiba' />
            <img src={img4} alt='Astro Shiba' />
            <img src={img3} alt='Astro Shiba' />
            <img src={img5} alt='Astro Shiba' />  
        </ImgContainer>
        <Title>Join <br /> Astro Shiba</Title>
        <a href='https://t.me/ASHIBTOKEN' rel='noreferrer' target='_blank'>
        <Button text='Join now' />
        </a>
        
    </Section>
  )
}

export default Banner